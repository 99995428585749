import React, { createContext, useContext } from "react"
import { PureAbility as Ability } from "@casl/ability"
import { createContextualCan } from "@casl/react"
// import { useAuth } from "./core/Auth"

const CaslContext = createContext({
  ability: {},
})
export const useCasl = () => useContext(CaslContext)

// const initialAbility = Object.freeze({ action: "read", subject: "Auth" })

const CaslProvider = ({ children }) => {
  // const { auth } = useAuth()
  // const canManagePermissions = { action: "manage", subject: "permissions", inverted: auth?.user?.role_id !== 1 }
  const _permissions = [{action:"manage",subject:"all"}];
  const ability = new Ability(_permissions)
  return <CaslContext.Provider value={ability}>{children}</CaslContext.Provider>
}

export const Can = createContextualCan(CaslContext.Consumer)

export default CaslProvider
