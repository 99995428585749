import { useIntl } from "react-intl"
import { MenuItem } from "./MenuItem"
// import { MenuInnerWithSub } from "./MenuInnerWithSub"
// import { MegaMenu } from "./MegaMenu"

// import { Can } from "../../../../../app/modules/auth/CaslContext"
// import { useAuth } from "../../../../../app/modules/auth/core/Auth"

export function MenuInner() {
  const intl = useIntl()
  // const { currentUser } = useAuth()

  return (
    <>
      <MenuItem title={intl.formatMessage({ id: "MENU.DASHBOARD" })} to='/dashboard' />

      {/* {currentUser?.role_text?.match(/[^(affiliate)]/i) && (
        <Can I='view' on='offers'>
          <MenuInnerWithSub title='Offers' to='/offers' menuPlacement='bottom-start' menuTrigger='hover'>
            <Can I='create' on='offers'>
              <MenuItem title='Create Offer' to='/offers/create' />
            </Can>

            <MenuItem title='Offers' to='/offers' />
          </MenuInnerWithSub>
        </Can>
      )} */}

      {/* <Can I='view' on='placements'>
        <MenuItem title='Placements' to='/affiliates/placements' />
      </Can>

      <Can I='read' on='advertisers'>
        <MenuItem title='Advertisers' to='/advertisers' />
      </Can>

      <Can I='read' on='affiliates'>
        <MenuItem title='Affiliates' to='/affiliates' />
      </Can> */}

      {/* <MenuItem title='Reports' to='/reports' /> */}

      {/* <Can I='manage' on='all'>
        <MenuInnerWithSub title='Admin Controls' to='/admin' menuPlacement='bottom-start' menuTrigger='hover'>
          <MenuItem title='User Management' to='/admin/users' />
        </MenuInnerWithSub>
      </Can> */}
    </>
  )
}
