import React, { createRoot } from "react-dom/client"
// Axios
import axios from "axios"
import { Chart, registerables } from "chart.js"
import { ToastContainer, Slide } from "react-toastify"

import { AppRoutes } from "./app/routing/AppRoutes"
import { AuthProvider, setupAxios } from "./app/modules/auth"

// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n"
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss"
import "./_metronic/assets/sass/plugins.scss"
import "./_metronic/assets/sass/style.react.scss"
import "react-toastify/dist/ReactToastify.css"
import { BrowserRouter } from "react-router-dom"
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const { PUBLIC_URL } = process.env
const container = document.getElementById("root")

if (container) {
  createRoot(container).render(
    <MetronicI18nProvider>
      <ToastContainer
        position='bottom-right'
        autoClose={1000}
        hideProgressBar={true}
        closeOnClick
        icon={false}
        rtl={false}
        draggable
        pauseOnHover
        theme='dark'
        newestOnTop
        transition={Slide}
      />
      <AuthProvider>
        <BrowserRouter basename={PUBLIC_URL}>
          <AppRoutes />
        </BrowserRouter>
      </AuthProvider>
    </MetronicI18nProvider>,
  )
}
