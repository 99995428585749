import React from "react"
// import { parseErrorToArray } from "../utils/ParseErrors"
import { KTSVG } from "../../../_metronic/helpers"

const dummyFunc = () => {}

const getToastIcon = type => {
  if (type === "success") return <KTSVG path='/media/icons/duotune/general/gen043.svg' className='svg-icon-muted svg-icon-2hx text-success me-1' />
  if (type === "info") return <KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-muted svg-icon-2hx text-info me-1' />
  if (type === "warning") return <KTSVG path='/media/icons/duotune/general/gen044.svg' className='svg-icon-muted svg-icon-2hx text-warning me-1' />
  if (type === "error") return <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon svg-icon-2hx text-danger me-1' />
  return <></>
}

const CustomToast = ({ messages = [], closeToast = dummyFunc, toastProps }) => (
  <ul className='list-group list-group-flush'>
    {messages.map((msg, i) => (
      <li key={i} className='list-group-item d-inline-flex justify-content-start align-items-center bg-transparent text-white p-1'>
        {getToastIcon(toastProps.type)}
        <p className='mb-0 fs-5 fw-bold'>{msg}</p>
      </li>
    ))}
  </ul>
)

export default CustomToast
