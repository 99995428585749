/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios"
const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth`
export const REGISTER_URL = `${API_URL}/users/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/reset_password`
export const VERIFY_TOKEN_URL = `${API_URL}/auth/verify_token`
export const VERIFY_EMAIL_URL = `${API_URL}/auth/verify_email`
export const REQUEST_SET_PASSWORD_URL = `${API_URL}/auth/reset_set_password`
export const GET_USERLIST_BY_ACCESSTOKEN_URL = `${API_URL}/admin/users/`
// export const GET_OFFER_DATA = `${API_URL}/data`

// Server should return AuthModel
export function login(email, password) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export async function register(data) {
  return await axios.post(REGISTER_URL, {
    email: data.email,
    name: data.name,
    password: data.password,
    password2: data.password2,
    company_name: data.company_name,
    role: data.role,
  })
}
// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function verify_token(token) {
  return axios.get(VERIFY_TOKEN_URL, { params: { token } })
}

export function verify_reset_pass_token(token) {
  return axios.post(VERIFY_TOKEN_URL, { token })
}

export function verify_email(token) {
  return axios.post(VERIFY_EMAIL_URL, { token })
}
//server should return object => { result: boolean}
export function resetSetPassword(token, email, password) {
  // console.log(email, password)
  return axios.post(
    REQUEST_SET_PASSWORD_URL,
    {
      email,
      password,
    },
    {
      headers: {
        "x-access-token": token,
      },
    },
  )
}
