import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"
import { KTSVG, useDebounce } from "../../../_metronic/helpers"
import DataTable from "react-data-table-component"
// import { deleteOffer } from "./core/_requests"
import { toast } from "react-toastify"
import { useThemeMode } from "../../../_metronic/partials"
import axios from "axios"
// import { FilterComponent } from "../shared-components/FilterComponent"
import ToolbarWrapper from "../shared-components/ToolbarWrapper"
// import ConfirmationModal from "../shared-components/ConfirmationModal"
// import PopOverToolTip from "../utils/PopOverTooltip"
import { Can /* , useCasl */ } from "../auth/CaslContext"

import "flag-icons/css/flag-icons.min.css"
import CustomToast from "../shared-components/CustomToast"
import { parseErrorToArray } from "../utils/ParseErrors"
// import { useAuth } from "../auth"

// const filterOptions = [
//   { key: "name", label: "Offer Name" },
//   { key: "offer_type", label: "Offer Type" },
//   { key: "advertiser", label: "Advertiser" },
//   { key: "countries", label: "Countries" },
//   { key: "os", label: "Operating systems" },
// ]

const statusOptions = [
  { label: "All", value: "0,1,2,3," },
  { label: "Under Review", value: 0 },
  { label: "Active", value: 1 },
  { label: "Paused", value: 2 },
  { label: "Disabled", value: 3 }
]

const API_URL = process.env.REACT_APP_API_URL

const ArrowDownward = () => <i className='las la-arrow-down' />

const LinearIndeterminate = () => (
  <div className='d-flex justify-content-center align-items-center h-350px'>
    <span className='spinner-border spinner-border-md align-middle ms-2' />
  </div>
)

const OfferTable = ({ toolbar = true }) => {
  // const ability = useCasl()
  // const { currentUser } = useAuth()
  const statusDropdownRef = useRef(null)
  const statusDropdownBtnRef = useRef(null)
  const theme = useThemeMode()
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortBy, setSortBy] = useState("id")
  const [sortOrder, setSortOrder] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  const [status, setStatus] = useState(statusOptions[0])
  // const [selectedValue, setSelectedValue] = useState([])
  const debouncedSearch = useDebounce(searchTerm, 500)

  // confirmation modal values
  // const [confirmationModal, setConfirmationModal] = useState({ show: false })

  const handlePageChange = page => setCurrentPage(page)

  // const updateOfferHandler = async (offerId, payload) => {
  //   try {
  //     setLoading(true)
  //     const res = await axios.post(`${API_URL}/offers/update/${offerId}`, payload)
  //     setLoading(false)
  //     if (res.status === 200 && res.data.success) {
  //       // const filteredOffer = data.find(({id}) => id === offerId)
  //       fetchOffers(1, selectedValue)
  //       toast.success(<CustomToast messages={res.data?.message} />)
  //     }
  //   } catch (err) {
  //     setLoading(false)
  //     toast.error(<CustomToast messages={parseErrorToArray(err)} />)
  //   }
  // }

  // const deleteHandler = async id => {
  //   setLoading(true)
  //   try {
  //     const res = await deleteOffer(id)
  //     if (res.data.success) {
  //       await fetchOffers(1, selectedValue)
  //       toast.success(<CustomToast messages={["Campaign deleted successfully"]} />)
  //     }
  //   } catch (err) {
  //     toast.error(<CustomToast messages={parseErrorToArray(err)} />)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const fetchOffers = useCallback(
    async (page, filters = [], searchText) => {
      setLoading(true)
      // const f = filters.map(obj => ({ key: obj.key, values: obj.values }))
      return axios
        .get(`${API_URL}/offers`, {
          params: {
            limit: perPage,
            page,
            order: sortOrder,
            sort: sortBy,
            search: searchText,
            // status: status?.value,
            start: (page - 1) * perPage
            //filters: JSON.stringify(f),
          }
        })
        .then(({ data }) => data)
        .then(response => {
          const { data, success } = response
          if (success) {
            setData(data?.offers)
            setTotalRows(data?.payload?.pagination?.total)
          }
        })
        .catch(err => {
          console.error(err.message)
          toast.error(<CustomToast messages={parseErrorToArray(err)} />)
        })
        .finally(() => setLoading(false))
    },
    [perPage, sortOrder, sortBy]
  )

  const handleSort = (column, sortDirection) => {
    setSortBy(column.sortField)
    setSortOrder(sortDirection)
  }

  useEffect(() => {
    if (statusDropdownBtnRef.current) statusDropdownBtnRef.current.classList.remove("show", "menu-dropdown")
    if (statusDropdownRef.current) {
      statusDropdownRef.current.classList.remove("show")
      statusDropdownRef.current.removeAttribute("style")
    }
  }, [status])

  useEffect(() => {
    fetchOffers(currentPage, [], debouncedSearch)
  }, [currentPage, debouncedSearch, fetchOffers])

  const columns = [
    {
      name: "ID",
      selector: row => `${row.id}`,
      sortable: true,
      sortField: "id",
      width: "75px",
      cell: (row, i) => row.id
    },
    {
      name: "App Icon",
      center: true,
      cell: row => row.icon && <img className='rounded rounded-1 shadow' src={row.icon} alt={""} height={35} width={35} />
    },
    {
      name: "Campaign Name",
      selector: row => `${row.name}`,
      sortable: true,
      sortField: "name",
      width: "250px",
      style: { display: "flex", justifyContent: "space-between", overflow: "hidden !important" },
      className: "froxen",
      cell: (row, i, column) => (
        <>
          <span className='overflow-hidden'>{`${row?.name} (${row?.external_id})`}</span>
        </>
      )
    },
    {
      name: "Bundle id",
      width: "150px",
      center: true,
      cell: (row, i, column) => {
        return row.bundle_id
      }
    },
    {
      name: "Package id",
      selector: row => row.package_id
    },
    {
      name: "Daily click cap",
      selector: row => row.daily_click_cap
    },
    {
      name: "Status",
      selector: row => `${row.status}`,
      center: true,
      cell: (row, i, column) => {
        switch (row.status) {
          case "pending":
            return <label className='badge badge-primary'>Under Review</label>
          case "active":
            return <label className='badge badge-success badge-sm mx-1'>Active</label>
          case "paused":
            return <label className='badge badge-warning badge-sm'>Paused</label>
          case "disabled":
            return <label className='badge badge-danger badge-sm'>Disabled</label>
          default:
            return "N/A"
        }
      }
    },
    {
      name: "Country",
      selector: row => `${row.country}`,
      center: true,
      width: "115px",
      style: { flexWrap: "wrap", justifyContent: "start" },
      cell: (row, i, column) => {
        return row?.country
      }
    },
    {
      name: "OS",
      selector: row => `${row.os}`,
      center: true,
      width: "125px",
      style: { flexWrap: "wrap", justifyContent: "center" },
      cell: row => {
        return row?.platform
      }
    },
    {
      name: "Actions",
      center: true,
      cell: row => {
        const Actions = offer => {
          return (
            <div className='d-flex flex-col gap-1 w-100 align-items-center justify-content-center'>
              <Can do='edit' this='offers'>
                <Link to={"/offers/edit/" + offer.id} className='btn btn-sm btn-icon btn-light-primary w-30px h-25px'>
                  <span className='las la-edit fs-5' />
                </Link>
              </Can>
            </div>
          )
        }
        return Actions(row)
      }
    }
  ]

  return (
    <Fragment>
      {toolbar && (
        <ToolbarWrapper>
          <div className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
            <h3>Offers</h3>
          </div>
          <div className='d-flex flex-row align-items-center gap-3 min-w-300px justify-content-end'>
            <div>
              <button
                ref={statusDropdownBtnRef}
                data-kt-menu-trigger="{default: 'hover'}"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                className='btn btn-sm btn-light-primary btn-active-light-primary'
              >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-5' />
                {status?.label || "All"}
              </button>
              <div
                ref={statusDropdownRef}
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
                data-kt-menu='true'
              >
                {statusOptions.map((obj, i) => (
                  <div
                    className={clsx("menu-item px-3 bg-hover-light-primary", {
                      "bg-light-primary hoverable": status && status.value === obj.value
                    })}
                    key={`filter_option_${i}`}
                  >
                    <div className='menu-content d-flex align-items-center px-3 cursor-pointer' onClick={e => setStatus(obj)}>
                      {obj.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Can do='create' a='offers'>
              <Link to='/offers/create' role='button' className='btn btn-sm btn-primary px-3 d-inline-flex align-items-center'>
                <i className='las la-plus-circle fs-2' /> Add
              </Link>
            </Can>
          </div>
        </ToolbarWrapper>
      )}

      <div className='row mb-5'>
        <div className='col-lg-3 mr-0'>
          <div className='position-relative'>
            <div className='input-group input-group-solid border border-color-gray-700'>
              <span className='input-group-text ps-6 bg-white border-0 cursor-pointer'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-3 svg-icon-gray-00 position-absolute top-50 translate-middle'
                />
              </span>
              <input
                type='text'
                className='form-control bg-body fs-7 form-control-sm border-0'
                placeholder='Search...'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={e => e.key === "Enter" && fetchOffers(1, [], searchTerm)}
              />

              {searchTerm?.length > 0 && (
                <span className='input-group-text ps-6 bg-white cursor-pointer' onClick={() => setSearchTerm("")}>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr015.svg'
                    className='svg-icon-3 position-absolute bg-body text-danger translate-middle top-50'
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`mb-5 mb-xl-8`}>
        <DataTable
          pagination
          fixedHeader
          columnsResize
          persistTableHead
          paginationServer
          data={data}
          columns={columns}
          theme={theme.mode}
          onSort={handleSort}
          progressPending={loading}
          paginationRowsPerPageOptions={[5, 10, 15, 25, 50]}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setPerPage}
          sortIcon={<ArrowDownward />}
          progressComponent={<LinearIndeterminate />}
          style={{ overflowX: "auto", minHeight: "500px" }}
          customStyles={{ tableWrapper: { style: { display: "grid", overflowX: "scroll" } } }}
        />
      </div>
    </Fragment>
  )
}

export default OfferTable
