import { lazy } from "react"
import { Route, Routes } from "react-router-dom"

// import { Registration } from "./components/Registration"
// import { ForgotPassword } from "./components/ForgotPassword"
// import ResetPassword from "./components/ResetPassword"
// import VerifyEmail from "./components/VerifyEmail"

const Login = lazy(() => import("./components/Login"))
const AuthLayout = lazy(() => import("./AuthLayout"))

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        {/* <Route path='registration' element={<Registration />} /> */}
        {/* <Route path='forgot-password' element={<ForgotPassword />} /> */}
        {/* <Route path='reset-password/:token' element={<ResetPassword />} /> */}
        {/* <Route path='verify_email/:token' element={<VerifyEmail />} /> */}
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}
export { AuthPage }
