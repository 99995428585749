import React from "react"

export const Toast = props => {
  return (
    <div className={`alert alert-${props.type || "success"} ms-5`}>
      <div className='alert-text font-weight-bold text-capitalize'>{props.text}</div>
    </div>
  )
}

const fallbackErrMsg = "Opps!!, Something went wrong, please try refreshing the page"
export const parseErrorToArray = err => {
  if (err?.response?.data) {
    const errMsg = err.response.data?.message

    if (errMsg && Array.isArray(errMsg)) return errMsg
    else if (typeof errMsg === "string") return [errMsg]
    else return [fallbackErrMsg]
  }

  return [err.message || fallbackErrMsg]
}
