import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import clsx from "clsx"
import { useAuth } from "../../../../app/modules/auth"
import { Toast } from "../../../../app/modules/utils/ParseErrors"
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components"

const initialValues = {
  name: "",
  company_name: "",
  contact_no: "",
  email: "",
  password: "",
  password2: "",
  acceptTerms: false,
}
const registrationSchema = Yup.object().shape({
  name: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required("Name is required"),
  email: Yup.string().email("Wrong email format").min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required("Email is required"),
  password: Yup.string().min(8, "Minimum 8 symbols").max(50, "Maximum 50 symbols").required("Password is required"),
  password2: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "Password and Confirm Password didn't match"),
    }),
  company_name: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required("Company name is required"),
  contact_no: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required("Company name is required"),
})

const NewUserForm = () => {
  const [loading, setLoading] = useState(false)
  const { message, hasErrors, setHasErrors } = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(false)
    },
  })
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  return (
    <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_add_new_user' onSubmit={formik.handleSubmit}>
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {!hasErrors && !!message.length && <Toast text={message} />}

      {hasErrors && message.map((text, idx) => <Toast key={idx} type='danger' text={text} />)}

      <div className='fv-row mb-2 row'>
        <label className='form-label fw-bolder text-dark fs-6 col-sm-3'>Full Name</label>
        <div className='col-sm-9'>
          <input
            placeholder='Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps("name")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.name && formik.errors.name,
              },
              {
                "is-valid": formik.touched.name && !formik.errors.name,
              },
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='fv-row mb-8 row'>
        <label className='form-label fw-bolder text-dark fs-6 col-sm-3'>Email</label>
        <div className='col-sm-9'>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              },
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='fv-row mb-5 row'>
        <label className='form-label fw-bolder text-dark fs-6 col-sm-3'>Company</label>
        <div className='col-sm-9'>
          <input
            type='text'
            placeholder='company name'
            autoComplete='off'
            {...formik.getFieldProps("company_name")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.company_name && formik.errors.company_name,
              },
              {
                "is-valid": formik.touched.company_name && !formik.errors.company_name,
              },
            )}
          />
          {formik.touched.company_name && formik.errors.company_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.company_name}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row mb-8 row'>
        <label className='form-label fw-bolder text-dark fs-6 col-sm-3'>Contact Number</label>
        <div className='col-sm-9'>
          <input
            placeholder='Contact No'
            type='tel'
            autoComplete='off'
            {...formik.getFieldProps("contact_no")}
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": formik.touched.contact_no && formik.errors.contact_no },
              {
                "is-valid": formik.touched.contact_no && !formik.errors.contact_no,
              },
            )}
          />
          {formik.touched.contact_no && formik.errors.contact_no && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.contact_no}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row mb-8 ' data-kt-password-meter='true'>
        <div className='mb-1 row'>
          <label className='form-label fw-bolder text-dark fs-6 col-sm-3'>Password</label>
          <div className='col-sm-9'>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid": formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid": formik.touched.password && !formik.errors.password,
                  },
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            <div className='text-muted'>Use 8 or more characters with a mix of letters, numbers & symbols.</div>
          </div>
          {/* end::Meter */}
        </div>
      </div>
      <div className='fv-row mb-5 row'>
        <label className='form-label fw-bolder text-dark fs-6 col-sm-3'>Confirm Password</label>
        <div className='col-sm-9'>
          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps("password2")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password2 && formik.errors.password2,
              },
              {
                "is-valid": formik.touched.password2 && !formik.errors.password2,
              },
            )}
          />
          {formik.touched.password2 && formik.errors.password2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password2}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          {" "}
          <button
            type='submit'
            id='kt_add_new_user_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: "block" }}>
                Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <div className='col-md-6'>
          <button type='button' id='kt_add_new_user_cancel_button' className='btn btn-lg btn-light-primary w-100 mb-5' data-bs-dismiss='modal'>
            Cancel
          </button>
        </div>
      </div>
    </form>
  )
}

export default NewUserForm
