const AUTH_LOCAL_STORAGE_KEY = "a9e8f2c42c58"
export const AUTH_ERR_MSG = "AUTH_ERR_MSG"

const getAuth = () => {
  try {
    if (!localStorage) return null

    const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)

    if (!lsValue) return null
    return JSON.parse(lsValue)
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error)
    return null
  }
}

const setAuth = auth => {
  if (!localStorage) return

  try {
    const lsValue = JSON.stringify(auth)

    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error)
  }
}

const removeAuth = () =>
  new Promise((resolve, reject) => {
    if (!localStorage) return

    try {
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
      return resolve()
    } catch (error) {
      console.error("AUTH LOCAL STORAGE REMOVE ERROR", error)
      return reject(error)
    }
  })

export function setupAxios(axios) {
  axios.defaults.headers.Accept = "application/json"

  axios.interceptors.request.use(
    config => {
      const auth = getAuth()

      if (auth && auth.api_token) {
        // config.headers.Authorization = `Bearer ${auth.api_token}`
        config.headers["x-access-token"] = auth.api_token
      }
      return config
    },
    err => Promise.reject(err),
  )

  axios.interceptors.response.use(
    response => response,
    err => {
      const errResponse = err?.response?.data

      if (errResponse?.errorCode === "ER_AUTHENTICATION") {
        removeAuth()
        localStorage.setItem(AUTH_ERR_MSG, "Session expired, please login again")
        window.location.reload()
      }

      return Promise.reject(err)
    },
  )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
