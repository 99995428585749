import React, { lazy, Suspense } from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import { MasterLayout } from "../../_metronic/layout/MasterLayout"
import TopBarProgress from "react-topbar-progress-indicator"
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils"
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper"
// import { useCasl } from "../modules/auth/CaslContext"

const PrivateRoutes = () => {
  // const ability = useCasl()
  // const UsersPage = lazy(() => import("../modules/apps/user-management/UsersPage"))
  
  const OfferPages = lazy(() => import("../modules/offers/OfferPages"))
  // const PlacementPages = lazy(() => import("../modules/publishers/PlacementPages"))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard/*' element={getSuspensedView(<DashboardWrapper />)} />

        {/* Lazy Modules */}
        <Route path='offers/*' element={getSuspensedView(<OfferPages />)} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary")
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 2,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

function getSuspensedView(comp) {
  return <SuspensedView>{comp}</SuspensedView>
}

export { PrivateRoutes }
