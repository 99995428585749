import React, { useEffect } from "react"
import { useLayout } from "../../../_metronic/layout/core"
import clsx from "clsx"

const ToolbarWrapper = ({ children }) => {
  const { config, classes } = useLayout()

  useEffect(() => {
    document.body.setAttribute("data-kt-app-toolbar-fixed", "true")
    return () => document.body.setAttribute("data-kt-app-toolbar-fixed", "false")
  }, [])

  return (
    <div id='kt_app_toolbar' className={clsx("app-toolbar d-flex align-items-center", classes.toolbar.join(" "), config?.app?.toolbar?.class)}>
      <div
        id='kt_app_toolbar_container'
        className={clsx(
          "app-container",
          classes.toolbarContainer.join(" "),
          config.app?.toolbar?.containerClass,
          { "app-toolbar-minimize": config.app?.toolbar?.minimize?.enabled },
          {
            "container-fluid": config.app?.toolbar?.container === "fluid",
            "container-xxl": config.app?.toolbar?.container === "fixed",
          },
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default ToolbarWrapper
