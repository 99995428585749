import React, { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "./core/Auth"

export function Logout() {
  const { logout, currentUser } = useAuth()

  useEffect(() => {
    currentUser && logout()
  }, [logout, currentUser])

  return !currentUser && <Navigate to='/auth/login' />
}
