import React, { useState, createContext, useContext, useEffect } from "react"
import * as authHelper from "./AuthHelpers"
import { toast } from "react-toastify"
import { login } from "./_requests"
import { verify_token } from "../core/_requests"
import { useCallback } from "react"
import CustomToast from "../../shared-components/CustomToast"
import { parseErrorToArray } from "../../utils/ParseErrors"

const initAuthContextPropsState = {
  loading: true,
  auth: {},
  saveAuth: auth => {},
  updateAuth: payload => {},
  currentUser: null,
  setCurrentUser: user => {},
  login: async () => {},
  logout: () => {},
  hasErrors: false,
  setHasErrors: error => {},
  message: null,
  setMessage: message => {},
}

const AuthContext = createContext(initAuthContextPropsState)
const useAuth = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [hasErrors, setHasErrors] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [auth, setAuth] = useState(null)
  // const [auth, setAuth] = useState(authHelper.getAuth())
  const [message, setMessage] = useState([])

  const saveAuth = async auth => {
    if (auth && auth.user) {
      setCurrentUser(auth.user)
      setAuth(auth)
      authHelper.setAuth(auth)
    } else {
      await authHelper.removeAuth()
    }
  }

  const updateAuth = useCallback(payload => {
    setAuth(prevAuth => ({ ...prevAuth, ...payload }))
  }, [])

  const logout = useCallback(async () => {
    await saveAuth(null)
    setCurrentUser(null)
  }, [])

  const loginUser = useCallback(async (email, password) => {
    try {
      setLoading(true)
      const { data: authData } = await login(email, password)
      await saveAuth(authData.data)

      toast.success(<CustomToast messages={["Login successful"]} />, { autoClose: 2000 })
    } catch (err) {
      toast.error(<CustomToast messages={parseErrorToArray(err)} />)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    try {
      setLoading(true)
      const localAuth = authHelper.getAuth()

      if (localAuth) {
        verify_token(localAuth.api_token)
          .then(() => {
            saveAuth(localAuth)
            setCurrentUser(localAuth.user)
            setLoading(false)
          })
          .catch(err => {
            localStorage.setItem(authHelper.AUTH_ERR_MSG, "Session expired, please login again.")
            logout()
            setLoading(false)
          })
      } else setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err.message)
      localStorage.setItem(authHelper.AUTH_ERR_MSG, err.message)
    }
  }, [logout])

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        updateAuth,
        currentUser,
        setCurrentUser,
        logout,
        hasErrors,
        setHasErrors,
        message,
        setMessage,
        login: loginUser,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, useAuth }
