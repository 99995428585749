export const initialQueryState = {
    page: 1,
    // sortby:'full_name'
};
export const initialQueryRequest = {
    state: initialQueryState,
    updateState: () => { },
};
export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' };
export const initialListView = {
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // MAIL => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemIdForUpdate: undefined,
    selected: [],
    onSelect: () => { },
    onSelectAll: () => { },
    clearSelected: () => { },
    setItemIdForUpdate: () => { },
    isAllSelected: false,
    disabled: false,
};
