import React from 'react'
// import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import NewUserForm from './NewUserForm';

const NewUser = () => {
  return (
    <div className='modal fade' id='kt_modal_new_users' aria-hidden='true'>
        <div className='modal-dialog mw-650px'>
            <div className='modal-content'>
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    {/* <div className='btn btn-sm btn-icon btn-active-color-primary ' data-bs-dismiss='modal'>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div> */}
                    
                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-10 mt-10 p-2'>Add a new user</h1>
                            <NewUserForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export  {NewUser}